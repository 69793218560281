.Footer {
  height: 50px;
  z-index: 10;
  bottom: 0;
  width: 100%;

  a {
    font-weight: 300;
    margin: 0 0.5em;
    color: #9698aa;
    &:hover {
      color: #6771e4;
    }
  }

  .socialBar {
    height: 100px;
    width: 100%;
    background: #444;
  }

  .bottomBar {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border-top: 1px solid #ededed;
  }

  .copyright {
    color: #9698aa;
    display: flex;
    align-items: center;
    padding-left: 3em;
  }

  .navLinks {
    z-index: 1000;
    padding-right: 3em;
    display: flex;
    align-items: center;
  }
}
