@import '../../styles/variables';

.HomePage {
  height: 100%;
  min-height: 100vh;

  .homeWrapper {
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 3px;

    @media (min-width: $screen-xs) {
      width: 95%;
    }
  }

  .contentWrapper {
    height: 100%;
    width: 100%;
    padding: 0.5em;
    border-radius: 3px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16), 0 2px 3px rgba(0, 0, 0, 0.03);
  }

  .content {
    height: 100%;
    width: 100%;
    z-index: 200;
    min-height: 100%;
  }

  .header {
    width: 100%;
    padding-top: 1em;
    padding-bottom: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .headerTitle {
    color: #555;
    font-size: 1.7em;
    margin: 0;
    padding: 0;
    margin-left: 0.5em;
  }

  .graphFilters {
    margin-top: -0.3em;
    color: #9698aa;
    display: flex;
  }
}
