$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';

@import 'variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap';

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  a {
    color: #6770e3;
    &:hover {
      text-decoration: none;
    }
    &:active {
      text-decoration: none;
    }
    &:focus {
      text-decoration: none;
    }
  }
  //
  // input:-webkit-autofill {
  //   -webkit-box-shadow: 0 0 0 30px white inset !important;
  // }
  //
  // input:focus,
  // input:-webkit-autofill:focus {
  //     border-color: #66afe9;
  //     -webkit-box-shadow: inset 0 0 0px 9999px white, 0 0 8px rgba(102, 175, 233, 0.6);
  // }

  ul.nav {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  ul.nav > li > a {
    padding: 0;
    margin: 0;
    margin-right: 1em;

    &:hover {
      background-color: #ffff;
    }
  }

  .loading-spinner-animation {
    width: 40px;
    height: 40px;
    margin: 100px auto;
    background-color: #6771e4;
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1s infinite ease-in-out;
    animation: sk-scaleout 1s infinite ease-in-out;
  }

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
    }
  }
}
