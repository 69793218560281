@import '../../../../styles/variables';

.VisualData {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-radius: 3px;
  border: 1px solid #ededed;
  // overflow: hidden;

  .graphWrapper {
    width: 100%;
  }

  .sidebarWrapper {
    width: 100%;
  }

  .panel {
    height: 100%;
  }

  @media (min-width: $screen-xs) {
  }

  @media (min-width: $screen-sm) {
    flex-wrap: nowrap;

    .graphWrapper {
      width: 66.66%;
      flex: 2;
    }

    .sidebarWrapper {
      width: 33.33%;
      flex: 1;
    }
  }
}
