@import '../../../../styles/variables';

.MarketCapStat {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -0.5em;
  // background-color: #fff;
  // border-left: 1px solid #EDEDED;
  // border-bottom: 1px solid #EDEDED;

  .stats {
    width: 100%;
    display: flex;
    color: #fff;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
  }

  .currencySymbol {
    font-weight: 300;
    margin-right: 0.1em;
  }

  .currencyPrice {
  }

  .currencyAbbrev {
    font-weight: 300;
    margin-left: 0.25em;
    margin-right: 0.25em;
  }

  .description {
    width: 100%;
    font-size: 0.9em;
    color: #fff;
    opacity: 0.8;
    text-align: center;
  }

  @media (min-width: $screen-xs) {
    margin-top: 0;

    .stats {
      font-size: 0.9em;
    }
  }

  @media (min-width: $screen-sm) {
    .stats {
      font-size: 1.2em;
    }
  }

  @media (min-width: $screen-md) {
    .stats {
      font-size: 1.4em;
    }
  }

  @media (min-width: $screen-lg) {
  }
}
