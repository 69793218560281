.TabTitle {
  margin-bottom: -0.15em;
  cursor: pointer;

  h3 {
    font-size: 1.1em;
    margin: 0;
    padding: 0.5em;
    color: #9698aa;
    font-weight: 300;
    border-bottom: 2px solid #fff;
  }
}

.ActiveTabTitle {
  h3 {
    font-size: 1.1em;
    margin: 0;
    padding: 0.5em;
    color: #000 !important;
    font-weight: 400 !important;
    border-bottom: 2px solid #72c9ef;
  }
}
