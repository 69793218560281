@import '../../../styles/variables';

.ReposList {
  width: 100%;

  h4 {
    margin: 0;
    font-size: 1em;
    font-weight: 400;
    margin-right: 0.5em;
    margin-bottom: 0.25em;
  }

  p {
    color: #9698aa;
    margin: 0;
    padding: 0;
    margin-top: 0;
    font-size: 0.9em;
    font-weight: 300;
  }

  .repos {
    padding: 0;

    @media (min-width: $screen-xs) {
      padding: 0 1em;
    }
  }

  .repoRow {
    width: 100%;
    border-bottom: 1px solid #ededed;
    padding: 0.5em 0.25em;
    display: flex;
    align-items: center;
  }

  .normal {
    color: #9698aa;
  }

  .active {
    color: #555;
  }

  .dropdownButton {
    color: #6771e4;
  }

  .dropdownIcon {
    margin-left: -0.75em;
    margin-right: 0.5em;
  }

  .dropdownPlaceholder {
    margin-left: 1em;
    color: #9698aa;
    font-weight: 300;
  }

  .topWrapper {
    width: 100%;
    padding: 1em 1em 0 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dividerWrapper {
    padding: 1em 1em 0 1em;
  }

  .divider {
    width: 100%;
    border-bottom: 1px solid #ededed;
  }

  .reposTitle {
    font-size: 1.4em;
    // color: #9698AA;
    color: #555;
    // font-weight: 300;
    margin-bottom: 0;
    margin-top: 1em;
  }

  .githubIcon {
    color: #555;
    margin-top: -0.2em;
  }

  .sortWrapper {
    display: flex;
    align-items: center;
    margin-top: 1em;
    margin-bottom: -0.75em;
    padding-right: 1em;
  }

  .sortTitle {
    color: #9698aa;
  }

  .repoItem {
    width: 100%;
    padding: 0.5em;
  }

  .titleContainer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .label {
    margin-top: -0.2em;
    margin-bottom: 0.25em;
    font-size: 0.8em;
    border-radius: 3px;
    color: #9698aa;
    padding: 0.2em 0.4em 0 0.4em;
    border: 1px solid #ededed;
    background: #f7f7f7;
  }

  .date {
    font-weight: 300;
    font-size: 0.85em;
    margin-bottom: 0.25em;
  }

  .flexDetails {
    display: flex;
    color: #9698aa;
    margin: 0;
    padding: 0;
    margin-top: 0;
    font-weight: 400;
  }

  .repoStat {
    margin-bottom: 0.25em;
    margin-right: 0.5em;
    font-size: 0.8em;
    border-radius: 3px;
    color: #9698aa;
    padding: 0.2em 0.4em 0 0.4em;
    border: 1px solid #ededed;
    background: #f7f7f7;
    display: flex;
    align-items: center;
  }

  .stat {
    margin-right: 0.3em;
  }

  .statIcon {
    margin-right: 0.15em;
    margin-bottom: -0.1em;
  }

  .statIcon {
    margin-top: -0.1em;
  }

  .paginationButtons {
    width: 100%;
    margin-top: 1em;
    margin-bottom: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .paginationButton {
    width: 6em;
    min-width: 6em;
    margin: 0 1em;
    color: #9698aa;
    border: 1px solid #ededed;
    // margin: 1em;
    &:hover {
      color: #fff;
      background-color: #6771e4;
      border: 1px solid #6771e4;
    }
    &:focus {
      background-color: #6771e4 !important;
      border: 1px solid #6771e4;
      color: #fff !important;
    }
    &:active {
      background-color: #6771e4 !important;
      border: 1px solid #6771e4;
      color: #fff !important;
    }
  }

  .paginationButtonWrap {
    width: 14em;
    display: flex;
    justify-content: center;
  }
}
