.CodeModalToggle {
  .largeCodeButton {
    color: #9698aa;
    border: 1px solid #ededed;
    font-size: 1.3em;
    margin-left: 0.5em;
    padding: 0 0.5em;
  }

  .smallCodeButton {
    color: #9698aa;
    border: 1px solid #ededed;
    margin: 0;
    padding: 0 0.5em;
    margin-top: -0.4em;
    margin-bottom: -0.2em;
    margin-left: 0.5em;
  }

  .codeIcon {
    margin-bottom: -0.2em;
  }
}
