.AdvancedFilter {
  .advancedSearchBox {
    width: 100%;
    padding: 0em 1.5em 1em 1.5em;
    color: #9698aa;
  }

  .filterRow {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .licenseFilter {
    width: 7em;
    margin-right: 2em;
  }

  .platformFilter {
    width: 12em;
    margin-right: 2em;
  }

  .sbcFilter {
    width: 24em;
    margin-right: 2em;
  }

  .categoryFilter {
    width: 41em;
    margin-right: 2em;
  }

  .filterTitle {
    margin-top: 0.5em;
    margin-bottom: 0.1em;
  }

  .filterItems {
    display: flex;
    flex-wrap: wrap;
  }

  .filterTag {
    cursor: pointer;
    margin-top: 0.25em;
    // margin-top: -0.2em;
    margin-bottom: 0.25em;
    margin-right: 0.5em;
    font-size: 0.8em;
    border-radius: 3px;
    padding: 0.2em 0.4em 0 0.4em;
  }

  .filterTagInactive {
    color: #9698aa;
    border: 1px solid #ededed;
    background: #f7f7f7;
  }

  .sbcTagActive {
    color: #ffb74d;
    border: 1px solid #ffe0b2;
    background: #fff3e0;
  }

  .openSourceTagActive {
    color: #81c784;
    border: 1px solid #c8e6c9;
    background: #e8f5e9;
  }

  .platformTagActive {
    color: #e57373;
    border: 1px solid #ffcdd2;
    background: #ffebee;
  }

  .categoryTagActive {
    color: #4fc3f7;
    border: 1px solid #b3e5fc;
    background: #e1f5fe;
  }
}
