@import '../styles/variables';

.SearchInput {
  display: flex;
  padding-left: 2em;

  .burger {
    display: flex;
    align-items: center;
    margin-right: 1em;
    margin-left: -1em;
    font-size: 1.5em;
    cursor: pointer;
  }

  .form {
    width: 100%;
    display: flex;
  }

  .formGroup {
    width: 100%;
    margin-bottom: 0;
    margin-right: 1.25em;
    position: relative;
  }

  .searchGlass {
    font-size: 1.5em;
    position: absolute;
    right: 8px;
    top: 7px;
    cursor: pointer;
  }

  .formControl {
    border-radius: 10px;
    background-color: transparent;
    border: 1px solid #c9cde2;
    color: #ededed;
    padding-right: 2.5em;
  }

  .formControl::placeholder {
    line-height: normal;
    color: #fff;
    font-size: 0.9em;
    font-weight: 300;
    opacity: 0.4;
  }

  .button {
    width: 10em;
  }

  .socialIcons {
    display: none;
  }

  .icon {
    font-size: 1.5em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    cursor: pointer;
  }

  .settingsIcon {
    font-size: 1.5em;
    margin-top: -0.1em;
    margin-left: 0.5em;
    color: #0eba81;
  }

  @media (min-width: $screen-sm) {
    .burger {
      display: none;
    }

    .formGroup {
      margin-right: 0;
    }

    .socialIcons {
      width: 25em;
      display: flex;
      justify-content: flex-end;
      padding-right: 2em;
      align-items: center;
    }
  }
}
