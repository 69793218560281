@import '../styles/variables';

.GoogleAd {
  display: flex;
  justify-content: center;
  align-items: center;

  .adUnitTop {
    height: 100px;
    width: 320px;

    @media (min-width: $screen-xs) {
      height: 60px;
      width: 468px;
    }

    @media (min-width: $screen-md) {
      height: 90px;
      width: 728px;
    }

    @media (min-width: $screen-lg) {
      width: 970px;
    }
  }

  .adUnitBottom {
    height: 280px;
    width: 320px;

    @media (min-width: $screen-xs) {
      width: 468px;
    }

    @media (min-width: $screen-md) {
      width: 728px;
    }

    @media (min-width: $screen-lg) {
      width: 970px;
    }
  }

  .adUnitHome {
    height: 100px;
    width: 320px;

    @media (min-width: $screen-xs) {
      height: 60px;
      width: 468px;
    }

    @media (min-width: $screen-md) {
      height: 90px;
      width: 728px;
    }

    @media (min-width: $screen-lg) {
      display: none;
    }
  }
}
