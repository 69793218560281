@import '../../styles/variables';

.AccountPage {
  height: 100%;
  min-height: 100vh;

  h2 {
    color: #555;
    font-size: 1.7em;
    margin: 0;
    padding: 0;
    margin-left: 0.5em;
  }

  .pageWrapper {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 3px;
    margin-top: 2em;

    @media (min-width: $screen-xs) {
      width: 95%;
    }
  }

  .contentWrapper {
    width: 100%;
    padding: 0.5em;
    border-radius: 3px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16), 0 2px 3px rgba(0, 0, 0, 0.03);
  }

  .content {
    height: 100%;
    width: 100%;
    z-index: 200;
    min-height: 100%;
  }

  .header {
    width: 100%;
    border-bottom: 1px solid #ededed;
    padding-top: 1em;
    padding-bottom: 1em;
    display: flex;
    align-items: center;
  }

  .detailWrap {
    height: 100%;
  }

  .detailsLoading {
    width: 100%;
    height: 15em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .adWrap {
    width: 100%;
    padding: 0 1em;
    margin: 0 auto;
  }

  .adWrapBottom {
    width: 100%;
    padding: 1em 0;
    margin: 0 auto;
  }
}
