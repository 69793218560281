@import '../styles/variables';

.PaginationButtons {
  border-top: 1px solid #ededed;

  .paginationWrapperDesktop {
    display: none;
  }

  .paginationWrapperMobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .paginationButton {
    color: #9698aa;
    border: 1px solid #ededed;
    margin: 1.3em 1em -0.2em 1em;
    &:hover {
      color: #fff;
      background-color: #6771e4;
      border: 1px solid #6771e4;
    }
    &:focus {
      background-color: #6771e4 !important;
      border: 1px solid #6771e4;
      color: #fff !important;
    }
    &:active {
      background-color: #6771e4 !important;
      border: 1px solid #6771e4;
      color: #fff !important;
    }
  }

  .mobileButtonLeft {
    color: #9698aa;
    border: 1px solid #ededed;
    padding: 5px 0.5em;
    margin: 1em 0.5em 1em 0.5em;
    &:hover {
      color: #fff;
      background-color: #6771e4;
      border: 1px solid #6771e4;
    }
    &:focus {
      background-color: #6771e4 !important;
      border: 1px solid #6771e4;
      color: #fff !important;
    }
    &:active {
      background-color: #6771e4 !important;
      border: 1px solid #6771e4;
      color: #fff !important;
    }
  }

  .mobileButtonRight {
    color: #9698aa;
    border: 1px solid #ededed;
    padding: 5px 0.5em;
    margin: 1em 1em 1em 0.5em;
    &:hover {
      color: #fff;
      background-color: #6771e4;
      border: 1px solid #6771e4;
    }
    &:focus {
      background-color: #6771e4 !important;
      border: 1px solid #6771e4;
      color: #fff !important;
    }
    &:active {
      background-color: #6771e4 !important;
      border: 1px solid #6771e4;
      color: #fff !important;
    }
  }

  @media (min-width: $screen-xs) {
    .paginationWrapperDesktop {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .paginationWrapperMobile {
      display: none;
    }
  }
}
