@import '../../styles/variables';

.Operations {
  padding: 0.5em 0;

  @media (min-width: $screen-xs) {
    padding: 1em;
  }

  .operationRow {
    border-top: 1px solid #ededed;
    padding: 0.5em 0.25em;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .operationContainer {
    width: 100%;
  }

  .noOperations {
    border-top: 1px solid #ededed;
    padding: 0.5em 0.25em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .largeLabel {
    width: 80%;
    text-align: center;
    font-size: 0.9em;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    border-radius: 3px;
    color: #9698aa;
    padding: 1em;
    border: 1px solid #ededed;
    background: #f7f7f7;
  }
}
