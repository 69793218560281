@import '../../../../styles/variables';

.Stats {
  width: 100%;
  min-height: 6.6em;

  .statWrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .panelWrapper {
    width: 100%;
    margin-bottom: 0.5em;
  }

  @media (min-width: $screen-xs) {
    .panelWrapper {
      width: 33.33%;
      margin-bottom: 2em;
    }
  }

  @media (min-width: $screen-sm) {
    flex-wrap: nowrap;
  }
}
