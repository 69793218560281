.DetailsSummary {
  margin: 0 0.5em;
  border-bottom: 1px solid #ededed;
  padding: 0.5em 0.5em 1em 0.4em;
  font-size: 1.1em;

  h3 {
    display: flex;
    align-items: center;
    margin-top: 0.5em;
    margin-bottom: 0.25em;
    color: #555;
    font-size: 1.1em;
  }

  .keyBreak {
    font-size: 0.9em;
    margin-bottom: 1em;
    color: #9698aa;
    word-wrap: break-word;
  }

  .mainDetails {
    font-size: 0.9em;
    margin-bottom: 1em;
    color: #9698aa;
  }

  .otherDetails {
    margin-top: 1em;
  }

  .keyValue {
    display: flex;
  }

  .key {
    color: #555;
    font-size: 0.9em;
  }

  .value {
    margin-left: 0.3em;
    color: #9698aa;
    font-size: 0.9em;
  }
}
