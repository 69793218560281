.Project {
  width: 100%;
  border-bottom: 1px solid #ededed;
  padding: 0.5em 0.25em;
  display: flex;
  align-items: center;

  h4 {
    margin: 0;
    font-size: 1em;
    font-weight: 400;
    margin-right: 0.5em;
    margin-bottom: 0.25em;
  }

  .projectItem {
    width: 100%;
    padding: 0.5em;
  }

  .titleContainer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .categoryIcon {
    color: #9698aa;
    border: 1px solid #ededed;
    background: #f7f7f7;
    border-radius: 5px;
    min-width: 3em;
    min-height: 3em;
    font-size: 1.4em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tags {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    display: flex;
  }

  .categoryTag {
    margin-top: -0.2em;
    margin-bottom: 0.25em;
    margin-right: 0.5em;
    font-size: 0.8em;
    border-radius: 3px;
    padding: 0.2em 0.4em 0 0.4em;
    color: #4fc3f7;
    border: 1px solid #b3e5fc;
    background: #e1f5fe;
    text-transform: capitalize;
  }

  .openSourceTag {
    margin-top: -0.2em;
    margin-bottom: 0.25em;
    margin-right: 0.5em;
    font-size: 0.8em;
    border-radius: 3px;
    padding: 0.2em 0.4em 0 0.4em;
    color: #81c784;
    border: 1px solid #c8e6c9;
    background: #e8f5e9;
  }

  .sbcTag {
    margin-top: -0.2em;
    margin-bottom: 0.25em;
    margin-right: 0.5em;
    font-size: 0.8em;
    border-radius: 3px;
    padding: 0.2em 0.4em 0 0.4em;
    color: #ffb74d;
    border: 1px solid #ffe0b2;
    background: #fff3e0;
  }

  .platformTag {
    margin-top: -0.2em;
    margin-bottom: 0.25em;
    margin-right: 0.5em;
    font-size: 0.8em;
    border-radius: 3px;
    padding: 0.2em 0.4em 0 0.4em;
    color: #e57373;
    border: 1px solid #ffcdd2;
    background: #ffebee;
    text-transform: capitalize;
  }

  .categoryTag {
    margin-top: -0.2em;
    margin-bottom: 0.25em;
    font-size: 0.8em;
    border-radius: 3px;
    padding: 0.2em 0.4em 0 0.4em;
    color: #4fc3f7;
    border: 1px solid #b3e5fc;
    background: #e1f5fe;
    text-transform: capitalize;
  }

  .flexDetails {
    display: flex;
    color: #9698aa;
    margin: 0;
    padding: 0;
    margin-top: 0;
    font-size: 0.9em;
    font-weight: 400;
  }

  .projectStat {
    margin-right: 1em;
  }

  .statIcon {
    margin-top: -0.2em;
  }

  .projectLink {
    color: #9698aa;
    &:hover {
      color: #6770e3;
    }
  }
}
