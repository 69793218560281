@import '../../styles/variables';

.Effects {
  padding: 0.5em 0;
  width: 100%;

  @media (min-width: $screen-xs) {
    padding: 1em;
  }

  h4 {
    display: flex;
    margin: 0;
    font-size: 1em;
    font-weight: 400;
    margin-right: 0.5em;
    margin-bottom: 0.25em;
  }

  p {
    color: #9698aa;
    margin: 0;
    padding: 0;
    margin-top: 0;
    font-size: 0.9em;
    font-weight: 300;
  }

  .date {
    color: #9698aa;
    margin: 0;
    padding: 0;
    margin-top: 0;
    display: flex;
    flex-wrap: wrap;
    font-weight: 300;
    font-size: 0.85em;
    margin-bottom: 0.25em;
  }

  .effectRow {
    width: 100%;
    border-top: 1px solid #ededed;
    padding: 0.5em 0.25em;
    display: flex;
    align-items: center;
  }

  .effectItem {
    width: 100%;
    padding: 0.5em;
  }

  .titleContainer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .label {
    margin-top: -0.2em;
    margin-bottom: 0.25em;
    font-size: 0.8em;
    border-radius: 3px;
    color: #9698aa;
    padding: 0.2em 0.4em 0 0.4em;
    border: 1px solid #ededed;
    background: #f7f7f7;
    text-transform: uppercase;
  }

  .flexDetails {
    display: flex;
    flex-wrap: wrap;
    text-transform: capitalize;
    color: #9698aa;
    margin: 0;
    padding: 0;
    margin-top: 0;
    font-size: 0.9em;
    font-weight: 400;
  }

  .noEffects {
    border-top: 1px solid #ededed;
    padding: 0.5em 0.25em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .largeLabel {
    width: 80%;
    text-align: center;
    font-size: 0.9em;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    border-radius: 3px;
    color: #9698aa;
    padding: 1em;
    border: 1px solid #ededed;
    background: #f7f7f7;
  }
}
