@import '../../styles/variables';

.OperationsPage {
  height: 100%;
  min-height: 100vh;

  h2 {
    color: #555;
    font-size: 1.7em;
    margin: 0;
    padding: 0;
    margin-left: 0.5em;
  }

  .pageWrapper {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #f7f7f7;
    border-radius: 3px;
    margin-top: 2em;

    @media (min-width: $screen-xs) {
      width: 95%;
    }
  }

  .contentWrapper {
    width: 100%;
    padding: 0.5em;
    border-radius: 3px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16), 0 2px 3px rgba(0, 0, 0, 0.03);
  }

  .content {
    border-radius: 3px;
    min-height: 55em;
    width: 100%;
    overflow: scroll;
  }

  .header {
    width: 100%;
    padding-top: 1.25em;
    padding-bottom: 0.2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .operationRow {
    padding: 0.5em;
    border-bottom: 1px solid #ededed;
  }

  .adWrap {
    width: 100%;
    padding: 0 1em;
    margin: 0 auto;
  }

  .adWrapBottom {
    width: 100%;
    padding: 1em 0;
    margin: 0 auto;
  }
}
