@import '../../../../styles/variables';

.PriceGraph {
  height: 100%;
  background-color: #fff;

  .loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .graph {
    height: 350px;
    // width: 100%;
  }

  .graphWrap {
    height: 350px;
    // width: 100%;
  }

  .smallWrap {
    display: inline-block;
    height: 350px;
    width: 120%;
    margin-left: -2.5em;
    margin-top: 0.75em;
  }

  .largeWrap {
    display: none;
  }

  @media (min-width: $screen-xs) {
    .largeWrap {
      display: inline-block;
      margin-right: -2.5em;
      height: 350px;
      width: 105%;
    }

    .smallWrap {
      display: none;
    }
  }
}
