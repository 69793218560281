@import '../../../../styles/variables';

.HorizonData {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  h2 {
    font-size: 1.4em;
    color: #555;
    margin-bottom: 0;
  }

  .header {
    width: 100%;
    padding: 1em 1em 0 1em;
    display: flex;
    justify-content: space-between;
  }

  .panelWrapper {
    width: 100%;
  }

  .adWrap {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 0 1em;
  }

  @media (min-width: $screen-lg) {
    flex-wrap: nowrap;

    .panelWrapper {
      width: 50%;
    }
  }
}
