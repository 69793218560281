@import '../../styles/variables';

.CommunityPage {
  height: 100%;
  min-height: 100vh;

  .pageTitle {
    color: #555;
    font-size: 1.7em;
    margin: 0;
    padding: 0;
    padding-bottom: 0.15em;
    margin-left: 0.5em;
  }

  h3 {
    margin-top: 0.5em;
    margin-bottom: 0.25em;
    color: #555;
    font-size: 1.1em;
  }

  .pageWrapper {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 3px;
    margin-top: 2em;

    @media (min-width: $screen-xs) {
      width: 95%;
    }
  }

  .contentWrapper {
    width: 100%;
    padding: 0.5em;
    border-radius: 3px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16), 0 2px 3px rgba(0, 0, 0, 0.03);
  }

  .content {
    width: 100%;
    overflow: scroll;
    border-radius: 3px;
    min-height: 55em;
  }

  .header {
    width: 100%;
    padding-top: 1.25em;
    padding-bottom: 1em;
    display: flex;
    align-items: center;
  }

  .dividerWrapper {
    width: 100%;
    padding: 0 1em 0 1em;
  }

  .divider {
    width: 100%;
    border-bottom: 1px solid #ededed;
  }

  .intro {
    padding: 1em 1.75em;
  }

  .introRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .introText {
    color: #9698aa;
    font-weight: 300;
  }

  .tabWrap {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  .adWrap {
    width: 100%;
    padding: 0 1em;
    margin: 0 auto;
  }

  .adWrapBottom {
    width: 100%;
    padding: 1em 0;
    margin: 0 auto;
  }
}
