@import '../../../styles/variables';

.Featured {
  margin-top: 1em;
  border-top: 1px solid #ededed;
  height: 100%;
  width: 100%;
  padding-bottom: 1em;
  overflow-y: scroll;

  .sectionTitle {
    font-size: 1.4em;
    color: #555;
    margin-bottom: 0;
  }

  .header {
    width: 100%;
    padding: 0 1em 0 1em;
    margin-top: 1.75em;
    display: flex;
  }

  .headerInside {
    width: 100%;
    padding: 0 1em 0.5em 0;
    border-bottom: 1px solid #ededed;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sliderWrap {
    display: flex;
    position: relative;
    height: 11em;
    margin-bottom: 1em;
    margin-top: 1em;
    padding-left: 1em;
    padding-right: 1em;
    .nextButton {
      display: none;
    }
    .prevButton {
      display: none;
    }
    &:hover {
      .nextButton {
        display: inline-block;
        position: absolute;
        cursor: pointer;
        right: 47%;
        font-size: 1.6em;
        bottom: 0.42em;
        z-index: 100;
        opacity: 0.6;
      }

      .prevButton {
        display: inline-block;
        position: absolute;
        cursor: pointer;
        right: 17%;
        font-size: 1.6em;
        top: 0.2em;
        z-index: 100;
        transform: rotate(180deg);
        opacity: 0.6;
      }
    }
  }

  .featuredSlider {
    width: 100%;
  }

  .verticalSlider {
    display: none;
  }

  .slide {
    cursor: pointer;
    width: 100%;
    height: 10.65em;
  }

  .vslide {
    width: 100%;
    height: 5.2em;
    margin-bottom: -0.495em;
  }

  .seeAll {
    margin-bottom: -1.5em;
  }

  .projects {
    padding: 0 1em;
  }

  @media (min-width: $screen-xs) {
    .sliderWrap {
      height: 16em;
      z-index: 100;
    }

    .slide {
      width: 100%;
      height: 15.65em;
    }
  }

  @media (min-width: 900px) {
    .sliderWrap {
      &:hover {
        .nextButton {
          right: 17%;
        }
      }
    }

    .featuredSlider {
      width: 65%;
    }

    .verticalSlider {
      display: inline-block;
      width: 35%;
    }
  }
}
