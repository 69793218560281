@import '../../styles/variables';

.AboutPage {
  height: 100%;
  min-height: 100vh;

  h2 {
    color: #555;
    font-size: 1.7em;
    margin: 0.75em;
    padding: 0;
    margin-left: 0.5em;
  }

  h3 {
    font-size: 1.3em;
    color: #555;
  }

  h5 {
    margin: 0;
    padding: 0;
  }

  .pageWrapper {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #f7f7f7;
    border-radius: 3px;
    margin-top: 2em;

    @media (min-width: $screen-xs) {
      width: 95%;
    }
  }

  .contentWrapper {
    width: 100%;
    padding: 0.5em;
    border-radius: 3px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16), 0 2px 3px rgba(0, 0, 0, 0.03);
  }

  .content {
    width: 100%;
    overflow: scroll;
    border-radius: 3px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: -1em;
  }

  .aboutWrap {
    padding: 1em 0;

    @media (min-width: $screen-xs) {
      padding: 1em;
    }
  }

  .aboutContent {
    border-top: 1px solid #ededed;
    padding: 1.5em 0.75em 2em 0.75em;
  }

  .intro {
    color: #9698aa;
  }

  .accordianTitle {
    cursor: pointer;
    padding: 0.75em 1em;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ededed;
  }

  .well {
    background-color: #f7f7f7;
    border: 1px solid #ededed;
    border-top: none;
    margin-bottom: 0.5em;
    border-radius: 0 0 3px 3px;
  }

  .arrowIcon {
    font-size: 1.5em;
    transform: rotate(0deg);
    transition: all 300ms linear;
  }

  .activeArrowIcon {
    font-size: 1.5em;
    transform: rotate(-180deg);
    transition: all 300ms linear;
  }

  @media (min-width: $screen-xs) {
    .aboutContent {
      padding: 1.5em 2em 2em 2em;
    }
  }

  @media (min-width: $screen-sm) {
  }

  @media (min-width: $screen-md) {
    .aboutContent {
      padding: 1.5em 4em 2em 4em;
    }
  }

  @media (min-width: $screen-lg) {
  }
}
