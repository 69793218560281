@import '../../styles/variables';

.ProjectsPage {
  .pageTitle {
    color: #555;
    font-size: 1.7em;
    margin: 0;
    padding: 0;
    margin-left: 0.5em;
    text-transform: capitalize;
  }

  h3 {
    margin-top: 0.5em;
    margin-bottom: 0.25em;
    color: #555;
    font-size: 1.1em;
  }

  .pageWrapper {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 3px;
    margin-top: 2em;

    @media (min-width: $screen-xs) {
      width: 95%;
    }
  }

  .contentWrapper {
    width: 100%;
    padding: 0.5em;
    border-radius: 3px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16), 0 2px 3px rgba(0, 0, 0, 0.03);
  }

  .content {
    height: 100%;
    width: 100%;
    z-index: 200;
    min-height: 75em;
  }

  .header {
    width: 100%;
    // border-bottom: 1px solid #EDEDED;
    padding-top: 1em;
    padding-bottom: 1em;
    display: flex;
    align-items: center;
  }

  .resultsInfo {
    color: #9698aa;
    margin-left: 1em;
    // padding: 1em 1.5em 0.5em 1.5em;
  }

  .resultsMinor {
    font-weight: 300;
  }

  .dividerWrapper {
    width: 100%;
    padding: 0 1em 0 1em;
  }

  .divider {
    width: 100%;
    border-bottom: 1px solid #ededed;
  }

  .topWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1em 1em 0 1em;
    justify-content: space-between;
  }

  .sortWrapper {
    display: flex;
    align-items: center;
    // margin-top: 1em;
    padding-right: 1em;
  }

  .sortTitle {
    color: #9698aa;
  }

  .dropdownButton {
    color: #6771e4;
  }

  .dropdownIcon {
    margin-left: -0.75em;
    margin-right: 0.5em;
  }

  .dropdownPlaceholder {
    margin-left: 1em;
    color: #9698aa;
    font-weight: 300;
  }

  .projects {
    padding: 0 1em;
  }

  .paginationWrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }

  .paginationButton {
    width: 6em;
    min-width: 6em;
    margin: 0 1em;
    color: #9698aa;
    border: 1px solid #ededed;
    // margin: 1em;
    &:hover {
      color: #fff;
      background-color: #6771e4;
      border: 1px solid #6771e4;
    }
    &:focus {
      background-color: #6771e4 !important;
      border: 1px solid #6771e4;
      color: #fff !important;
    }
    &:active {
      background-color: #6771e4 !important;
      border: 1px solid #6771e4;
      color: #fff !important;
    }
  }

  .paginationButtonWrap {
    width: 14em;
    display: flex;
    justify-content: center;
  }

  .adWrap {
    width: 100%;
    padding: 0 1em;
    margin: 0 auto;
  }

  .adWrapBottom {
    width: 100%;
    padding: 1em 0;
    margin: 0 auto;
  }
}
