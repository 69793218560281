.ProjectsList {
  padding: 0 1em;

  .paginationButtons {
    width: 100%;
    margin-top: 1em;
    margin-bottom: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .paginationButtonWrap {
    width: 14em;
    display: flex;
    justify-content: center;
  }

  .paginationButton {
    width: 6em;
    min-width: 6em;
    margin: 0 1em;
    color: #9698aa;
    border: 1px solid #ededed;
    // margin: 1em;
    &:hover {
      color: #fff;
      background-color: #6771e4;
      border: 1px solid #6771e4;
    }
    &:focus {
      background-color: #6771e4 !important;
      border: 1px solid #6771e4;
      color: #fff !important;
    }
    &:active {
      background-color: #6771e4 !important;
      border: 1px solid #6771e4;
      color: #fff !important;
    }
  }

  .noProjects {
    padding: 0.5em 0.25em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .largeLabel {
    width: 80%;
    text-align: center;
    font-size: 0.9em;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    border-radius: 3px;
    color: #9698aa;
    padding: 1em;
    border: 1px solid #ededed;
    background: #f7f7f7;
  }
}
