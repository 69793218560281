.DetailsComponent {
  .mainDetails {
    color: #9698aa;
    font-size: 0.9em;
    margin-bottom: 1em;
  }

  h3 {
    display: flex;
    align-items: center;
    margin-top: 0.5em;
    margin-bottom: 0.25em;
    color: #555;
    font-weight: 300;
    font-size: 1.1em;
  }

  .otherDetails {
    margin-top: 1em;
  }

  .key {
    font-size: 0.9em;
  }

  .keyValue {
    display: flex;
    margin-bottom: 0.25em;
    align-items: center;
  }

  .value {
    margin-left: 0.3em;
    color: #9698aa;
    font-size: 0.9em;
  }

  .keyBreakMain {
    color: #9698aa;
    font-size: 0.9em;
    margin-bottom: 1em;
    word-wrap: break-word;
  }

  .keyBreakValue {
    margin-left: 0.3em;
    color: #9698aa;
    font-size: 0.9em;
    word-wrap: break-word;
  }

  .statusIcon {
    margin-right: 3px;
  }

  .successLabel {
    display: flex;
    align-items: center;
    margin-top: -0.3em;
    font-size: 0.9em;
    border-radius: 3px;
    padding: 0.1em 0.4em 0 0.4em;
    color: #81c784;
    border: 1px solid #c8e6c9;
    background: #e8f5e9;
  }

  .failedLabel {
    display: flex;
    align-items: center;
    margin-top: -0.3em;
    font-size: 0.9em;
    border-radius: 3px;
    padding: 0.1em 0.4em 0 0.4em;
    color: #e57373;
    border: 1px solid #ffcdd2;
    background: #ffebee;
  }
}
