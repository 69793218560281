@import '../../../../styles/variables';

.PriceStat {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .stats {
    width: 100%;
    display: flex;
    color: #fff;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
  }

  .currencySymbol {
    font-weight: 300;
    margin-right: 0.1em;
  }

  .currencyPrice {
  }

  .currencyAbbrev {
    font-weight: 300;
    margin-left: 0.25em;
    margin-right: 0.25em;
  }

  .percentWrapper {
    justify-content: baseline;
    align-items: center;
    display: flex;
    font-size: 0.7em;
  }

  .positive {
    color: #0eba81;
    margin-right: 0.25em;
  }

  .negative {
    color: #d9524e;
    margin-right: 0.25em;
  }

  .noChange {
    color: #fff;
    opacity: 0.8;
  }

  .description {
    width: 100%;
    font-size: 0.9em;
    color: #fff;
    opacity: 0.8;
    text-align: center;
  }

  .upIcon {
    font-size: 1.5em;
    color: #0eba81;
  }

  .downIcon {
    font-size: 1.5em;
    color: #d9524e;
  }

  .neutralIcon {
    font-size: 1.5em;
    color: #fff;
  }

  @media (min-width: $screen-xs) {
    .stats {
      font-size: 0.9em;
    }
  }

  @media (min-width: $screen-sm) {
    .stats {
      font-size: 1.2em;
    }
  }

  @media (min-width: $screen-md) {
    .stats {
      font-size: 1.4em;
    }
  }

  @media (min-width: $screen-lg) {
  }
}
