@import '../../../styles/variables';

.AdvancedSearch {
  margin-top: 1em;
  border-top: 1px solid #ededed;
  width: 100%;

  h4 {
    margin: 0;
    font-size: 1em;
    font-weight: 400;
    margin-right: 0.5em;
    margin-bottom: 0.25em;
  }

  p {
    color: #9698aa;
    margin: 0;
    padding: 0;
    margin-top: 0;
    font-size: 0.9em;
    font-weight: 300;
  }

  .tag {
    margin-top: -0.2em;
    margin-bottom: 0.25em;
    margin-right: 0.5em;
    font-size: 0.8em;
    border-radius: 3px;
    color: #9698aa;
    padding: 0.2em 0.4em 0 0.4em;
    border: 1px solid #ededed;
    background: #f7f7f7;
  }

  .normal {
    color: #9698aa;
  }

  .active {
    color: #555;
  }

  .topWrapper {
    width: 100%;
    padding: 1em 1em 0 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .resultsInfo {
    color: #9698aa;
    padding: 1em 1.5em 0.5em 1.5em;
  }

  .resultsMinor {
    font-weight: 300;
  }

  .dividerWrapper {
    width: 100%;
    padding: 0 1em 0 1em;
  }

  .divider {
    width: 100%;
    border-bottom: 1px solid #ededed;
  }

  .projectsTitle {
    font-size: 1.4em;
    color: #555;
    padding-left: 0.5em;
    margin-bottom: 0;
    margin-top: 1em;
  }

  .githubIcon {
    color: #555;
    margin-top: -0.2em;
  }

  .searchWrapper {
    flex-wrap: wrap;

    width: 100%;
    display: flex;
    align-items: center;
    padding: 1em 1.5em 0 1.5em;
  }

  .formGroup {
    width: 100%;
    position: relative;
  }

  .searchInput {
    margin-bottom: -0.5em;
    border-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #c9cde2;
    padding-right: 2.5em;
    line-height: 40px;
  }

  .searchInput::placeholder {
    line-height: normal;
    font-size: 0.9em;
    font-weight: 300;
  }

  .advancedButton {
    margin-top: -0.65em;
    color: #6771e4;
  }

  .arrowIcon {
    font-size: 1.4em;
    transform: rotate(0deg);
    transition: all 300ms linear;
  }

  .activeArrowIcon {
    font-size: 1.4em;
    transform: rotate(-180deg);
    transition: all 300ms linear;
  }

  .advancedWrap {
    margin-top: 0.5em;
    width: 45em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .searchGlass {
    font-size: 1.5em;
    z-index: 100;
    position: absolute;
    right: 8px;
    top: 7px;
    cursor: pointer;
  }

  .projectSearch {
    width: 100%;
    padding: 1.2em 1em 0 1em;
    display: flex;
  }

  .label {
    margin-top: -0.2em;
    margin-bottom: 0.25em;
    font-size: 0.8em;
    border-radius: 3px;
    color: #9698aa;
    padding: 0.2em 0.4em 0 0.4em;
    border: 1px solid #ededed;
    background: #f7f7f7;
  }

  .date {
    font-weight: 300;
    font-size: 0.85em;
    margin-bottom: 0.25em;
  }

  .openSourceTagActive {
    color: #4caf50;
    border: 1px solid #4caf50;
    background: #e8f5e9;
  }

  .filterButton {
    margin-bottom: -1.5em;
    color: #9698aa;
    border-radius: 10px;
    border: 1px solid #c9cde2;
    &:hover {
      color: #fff;
      background-color: #6771e4;
      border: 1px solid #6771e4;
    }
    &:focus {
      background-color: #6771e4 !important;
      border: 1px solid #6771e4;
      color: #fff !important;
    }
    &:active {
      background-color: #6771e4 !important;
      border: 1px solid #6771e4;
      color: #fff !important;
    }
  }

  @media (min-width: $screen-xs) {
  }

  @media (min-width: $screen-sm) {
    .searchWrapper {
      flex-wrap: nowrap;
    }

    .filterButton {
      margin-top: -1em;
      margin-left: 0.5em;
      margin-bottom: 0;
    }

    .advancedWrap {
      margin-top: 0;
      width: 15em;
    }

    .searchInput {
      margin-bottom: 0;
    }
  }

  @media (min-width: $screen-md) {
  }

  @media (min-width: $screen-lg) {
  }
}
