.NotFoundPage {
  height: 100%;
  min-height: 100vh;

  h2 {
    color: #9698aa;
    font-weight: 300;
    font-size: 2em;
  }

  h3 {
    color: #9698aa;
    font-weight: 300;
    font-size: 1.1em;
  }

  .pageWrapper {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    background-color: #f7f7f7;
    border-radius: 3px;
    margin-top: 2em;
  }

  .contentWrapper {
    width: 100%;
    padding: 0.5em;
    border-radius: 3px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16), 0 2px 3px rgba(0, 0, 0, 0.03);
  }

  .content {
    width: 100%;
    overflow: scroll;
    min-height: 100%;
    height: 30em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .homeButton {
    margin-top: 1em;
    color: #9698aa;
    border: 1px solid #ededed;
    &:hover {
      color: #fff;
      background-color: #6771e4;
      border: 1px solid #6771e4;
    }
  }
}
