.HashPopover {
  .truncateString {
    margin-left: 0.2em;
    margin-right: 0.2em;
    width: 5em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.MatchedHash {
  .truncateString {
    margin-left: 0.2em;
    margin-right: 0.2em;
    width: 5em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #444;
  }
}
