@import '../../../styles/variables';

.GraphTimeframeButton {
  .filter {
    font-weight: 300;
    margin-right: 1.5em;
    padding: 0.5em 0.5em 0.2em 0.5em;
    cursor: pointer;
  }

  .activeFilter {
    color: #000;
    font-weight: 400;
    border-bottom: 2px solid #72c9ef;
  }

  .smallFilter {
    display: inline-block;
    margin-right: -0.4em;
    text-transform: capitalize;
  }

  .largeFilter {
    display: none;
  }

  @media (min-width: $screen-xs) {
    .smallFilter {
      display: none;
    }

    .largeFilter {
      display: inline-block;
    }
  }
}
