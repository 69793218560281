@import './styles/variables';

.App {
  height: 100%;
  width: 100%;
  background: #f7f7f7;
  display: flex;

  .sideBar {
    position: fixed;
    min-height: 100vh;
    width: 15em;
    min-width: 15em;
    z-index: 300;
    top: 0;
    bottom: 0;
    background-color: #fff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16), 0 2px 3px rgba(0, 0, 0, 0.03);
    transition: margin-left 0.3s ease;
  }

  .sideBarInside {
    width: 15em;
    min-width: 15em;
    height: 100%;
    position: fixed;
    top: 0;
  }

  .mainContent {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: relative;
    overflow-y: hidden;
    z-index: 10;
    transition: margin-left 0.5s ease;
    padding-bottom: 4em;
  }

  .footer {
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  .sideBarOpen {
    margin-left: 0;
  }

  .sideBarClosed {
    margin-left: -15em;
  }

  .searchContainer {
    padding-top: 1em;
    z-index: 100;
    height: 20em;
    width: 100%;
  }

  .blueBack {
    width: 100%;
    height: 20em;
    z-index: -1;
    background: #1488cc; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to bottom,
      #2b32b2,
      #1488cc
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to bottom,
      #2b32b2,
      #1488cc
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    position: fixed;
    z-index: 1;
    top: 0;
  }

  .pageWrapper {
    min-height: 100%;
    margin-top: -14.5em;
    padding-bottom: 2em;
  }

  @media (min-width: $screen-xs) {
    .pageWrapper {
      padding-bottom: 2em;
    }
  }

  @media (min-width: $screen-sm) {
    .sideBar {
      position: relative;
      margin-left: 0;
      transition: margin-left 0.5s ease;
    }
  }

  @media (min-width: $screen-md) {
  }

  @media (min-width: $screen-lg) {
  }
}

.AdminApp {
  // ul.nav > li > a {
  // 	padding: 0.75em;
  //   margin: 0.75em;
  //   margin-right: 1em;
  //
  //   &:hover {
  //     background-color: #FFFF;
  //   }
  // }
}
