@import '../../../styles/variables';

.Categories {
  margin-top: 1em;
  border-top: 1px solid #ededed;
  width: 100%;
  padding-bottom: 1em;
  display: flex;
  flex-wrap: wrap;

  h4 {
    margin: 0;
    font-size: 1em;
    font-weight: 400;
    margin-right: 0.5em;
    margin-bottom: 0.25em;
  }

  .category {
    width: 100%;
    margin-top: 1em;
    padding: 0.5em 0.25em;
    display: flex;
    align-items: flex-start;
  }

  .categoryIcon {
    color: #9698aa;
    border: 1px solid #ededed;
    background: #f7f7f7;
    border-radius: 5px;
    min-width: 3em;
    min-height: 3em;
    font-size: 1.4em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .categoryItem {
    width: 100%;
    padding: 0.5em;
  }

  .flexDetails {
    display: flex;
    color: #9698aa;
    margin: 0;
    padding: 0;
    margin-top: 0;
    font-size: 0.9em;
    font-weight: 400;
  }

  @media (min-width: $screen-xs) {
    .category {
      width: 50%;
    }
  }
}
