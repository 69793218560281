@import 'colors';
@import 'fonts';

//== Colors
//
//## Gray and brand colors for use across Bootstrap.
$brand-primary: #6771e4 !default;
$brand-success: #5cb85c !default;
$brand-info: #5bc0de !default;
$brand-warning: #f0ad4e !default;
$brand-danger: #d9534f !default;

// Typography
//
//## Font, line-height, and color for body text, headers, and more.
$font-family-sans-serif: 'Poppins', 'Helvetica Neue', Helvetica, Arial,
  sans-serif !default;
$font-family-serif: 'Poppins', Georgia, 'Times New Roman', Times, serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, 'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif !default;

$headings-font-family: 'Poppins', sans-serif;

// Buttons
//
//## For each of Bootstrap's buttons or custom, define text, background and border color..
$btn-primary-color: #fff !default;
$btn-primary-bg: $brand-primary !default;
$btn-primary-border: darken($btn-primary-bg, 5%) !default;
$btn-primary-hover: darken($btn-primary-bg, 10%) !default;
$btn-primary-active: darken($btn-primary-bg, 15%) !default;

$btn-secondary-color: #fff !default;
$btn-secondary-bg: $brand-secondary !default;
$btn-secondary-border: darken($btn-secondary-bg, 5%) !default;
$btn-secondary-hover: darken($btn-secondary-bg, 10%) !default;
$btn-secondary-active: darken($btn-secondary-bg, 15%) !default;

// Components
//
//## Define common padding and border radius sizes and more.
$border-radius-base: 3px !default;
$border-radius-large: 3px !default;
$border-radius-small: 3px !default;

// Navbar
//
//## Basics of a Navbar
$navbar-border-radius: 0px;
$navbar-margin-bottom: 0px;

$navbar-default-bg: #fff !default;
// Modal
//
//## Basics of a Modal
// $modal-title-padding: 10px !default;

// Media queries breakpoints
//
// ## Define the breakpoints at which your layout will change, adapting to different screen sizes.
// $screen-xs: 480px !default;
// $screen-sm: 768px !default;
// $screen-md: 992px !default;
// $screen-lg: 1200px !default;
$screen-xs: 500px !default;
$screen-sm: 800px !default;
$screen-md: 992px !default;
$screen-lg: 1246px !default;

// Panel
$panel-inner-border: #ededed !default;

// Popovers
$popover-max-width: none !default;

// Tabs
$nav-tabs-border-color: #fff !default;
$nav-tabs-link-hover-border-color: #fff !default;
$nav-tabs-active-link-hover-bg: #fff !default;
$nav-tabs-active-link-hover-border-color: #fff !default;
