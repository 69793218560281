@import '../styles/variables';

.Sidebar {
  height: 100%;
  position: relative;

  .logoWrapper {
    margin-top: 2.5em;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .logo {
    position: relative;
  }

  .ring1 {
    height: 4em;
    width: 4em;
    position: absolute;
    top: 5px;
    left: 7px;
    border-radius: 50%;
    border: 4px solid #2059bd;
    transform: skewX(-60deg);
  }

  .circle {
    height: 5em;
    width: 5em;
    border-radius: 50%;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16), 0 2px 3px rgba(0, 0, 0, 0.03);
    background: #1488cc; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to top,
      #1488cc,
      #2b32b2
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #1488cc, #2b32b2);
  }

  .smallCircle {
    height: 12px;
    width: 12px;
    left: 30px;
    bottom: 19px;
    background-color: #0eba81;
    border-radius: 50%;
    position: absolute;
    opacity: 0.9;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16), 0 2px 3px rgba(0, 0, 0, 0.03);
  }

  h1 {
    margin-top: 1em;
    font-size: 2.7em;
    font-weight: 700;
  }

  .closeIcon {
    position: absolute;
    right: 5px;
    top: 0;
    font-size: 1.5em;
    cursor: pointer;
  }

  .brand {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navListSmall {
    display: block;
    margin-top: 0.5em;
    padding: 0.5em 0.75em 0 0.75em;
  }

  .navListLarge {
    display: none;
  }

  .navButton {
    width: 100%;
    font-size: 1em;
    margin-bottom: 0.5em;
    display: flex;
    align-items: center;
    border: none;
    color: #9698aa;

    .icon {
      margin-right: 0.75em;
      font-size: 1.5em;
    }

    .tutorials {
      color: #4dd0e1;
    }

    .developers {
      color: #ff9800;
    }

    .community {
      color: #00bcd4;
    }

    .dashboard {
      color: #6771e4;
    }

    .operations {
      color: #03a9f4;
    }

    .ledger {
      color: #f44336;
    }

    .transactions {
      color: #e91e63;
    }

    .assets {
      color: #ffc107;
    }

    &:hover {
      background-color: #6771e4;
      color: #fff;
      .icon {
        color: #fff;
      }
    }
    &:focus {
      background-color: #6771e4 !important;
      color: #fff !important;
      .icon {
        color: #fff;
      }
    }
    &:active {
      background-color: #6771e4 !important;
      color: #fff !important;
      .icon {
        color: #fff;
      }
    }
  }

  .activeNavButton {
    background-color: #6771e4;
    color: #fff;

    .tutorials {
      color: #fff;
    }

    .dashboard {
      color: #fff;
    }

    .operations {
      color: #fff;
    }

    .ledger {
      color: #fff;
    }

    .assets {
      color: #fff;
    }

    .transactions {
      color: #fff;
    }

    .developers {
      color: #fff;
    }

    .community {
      color: #fff;
    }
  }

  @media (min-width: $screen-xs) {
  }

  @media (min-width: $screen-sm) {
    .navListLarge {
      display: block;
      margin-top: 0.5em;
      padding: 0.5em 0.75em 0 0.75em;
    }

    .navListSmall {
      display: none;
    }

    .closeIcon {
      display: none;
    }
  }

  @media (min-width: $screen-md) {
  }

  @media (min-width: $screen-lg) {
  }
}
