@import '../../styles/variables';

.DevelopersPage {
  height: 100%;
  min-height: 100vh;

  h2 {
    color: #555;
    font-size: 1.7em;
    margin: 0;
    padding: 0;
    padding-bottom: 0.15em;
    margin-left: 0.5em;
  }

  h3 {
    margin-top: 0.5em;
    margin-bottom: 0.25em;
    color: #555;
    font-size: 1.1em;
  }

  .pageWrapper {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #f7f7f7;
    border-radius: 3px;
    margin-top: 2em;

    @media (min-width: $screen-xs) {
      width: 95%;
    }
  }

  .contentWrapper {
    width: 100%;
    padding: 0.5em;
    border-radius: 3px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16), 0 2px 3px rgba(0, 0, 0, 0.03);
  }

  .content {
    width: 100%;
    overflow: scroll;
    border-radius: 3px;
    min-height: 75em;
  }

  .header {
    width: 100%;
    padding-top: 1.25em;
    padding-bottom: 1em;
    display: flex;
    align-items: center;
  }

  .dividerWrapper {
    width: 100%;
    padding: 0 1em 0 1em;
  }

  .divider {
    width: 100%;
    border-bottom: 1px solid #ededed;
  }

  .settings {
    padding: 1em 1.75em;
  }

  .settingsIcon {
    margin-top: -0.2em;
  }

  .enabledText {
    color: #0eba81;
  }

  .disabledText {
    color: #d9524e;
  }

  .settingsRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .explanation {
    width: 80%;
    color: #9698aa;
    font-weight: 300;
  }

  .toggleWrapper {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .toggle {
    position: relative;
    width: 3em;
    height: 1.35em;
    border: 1px solid #ededed;
    display: flex;
    border-radius: 10px;
    cursor: pointer;
  }

  .toggledOffBg {
    width: 50%;
    height: 100%;
    border-radius: 10px 0 0 10px;
    background-color: rgba(103, 113, 228, 0.6);
  }

  .toggledOnBg {
    width: 50%;
    height: 100%;
    border-radius: 0 10px 10px 0;
    background-color: #ededed;
  }

  .toggleButton {
    border-radius: 50%;
    width: 1.75em;
    height: 1.75em;
    position: absolute;
    z-index: 100;
    margin-top: -0.28em;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.01);
  }

  .toggledOn {
    top: 0;
    right: -0.05em;
    background-color: #6771e4;
    border: 1px solid #6771e4;
    transition: all 200ms ease;
  }

  .toggledOff {
    top: 0;
    right: 1.2em;
    background-color: #fff;
    border: 1px solid #ededed;
    transition: all 200ms ease;
  }

  .adWrap {
    width: 100%;
    padding: 0 1em;
    margin: 0 auto;
  }

  .adWrapBottom {
    width: 100%;
    padding: 1em 0;
    margin: 0 auto;
  }
}
