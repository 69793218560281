@import '../../../../styles/variables';

.Exchanges {
  width: 100%;

  h2 {
    font-size: 1.4em;
    color: #555;
    margin-bottom: 0;
  }

  .exchangesWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .exchangeWrap {
    width: 100%;
    height: 5em;
    border: 1px solid #ededed;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5em 1em 0 1em;
  }

  .header {
    width: 100%;
    padding: 0 1em 0 1em;
    display: flex;
  }

  .headerInside {
    width: 100%;
    padding: 0 1em 1em 0;
    border-bottom: 1px solid #ededed;
  }

  .binanceWrapper {
    margin: 1em 0.5em;
    height: 100%;
    width: 100%;
  }

  .binance {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-image: url('https://s3.amazonaws.com/stellarup-assets/images/binance.png');
  }

  .bittrexWrapper {
    margin: 1em 0.5em;
    height: 100%;
    width: 100%;
  }

  .bittrex {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-image: url('https://s3.amazonaws.com/stellarup-assets/images/bittrex.png');
  }

  .krakenWrapper {
    margin: 1em 0.5em;
    padding: 0 0.5em 0.3em 0.5em;
    height: 100%;
    width: 100%;
  }

  .kraken {
    height: 100%;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('https://s3.amazonaws.com/stellarup-assets/images/kraken.png');
  }

  .cexWrapper {
    margin: 1em 0.5em;
    padding: 0.75em 0.5em;
    height: 100%;
    width: 100%;
  }

  .cex {
    height: 100%;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('https://s3.amazonaws.com/stellarup-assets/images/cex.png');
  }

  .qryptosWrapper {
    margin: 1em 0.5em;
    padding: 0 0.5em;
    height: 100%;
    width: 100%;
  }

  .qryptos {
    height: 100%;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('https://s3.amazonaws.com/stellarup-assets/images/qryptos.png');
  }

  .poloniexWrapper {
    margin: 1em 1em 1em 0.5em;
    height: 100%;
    width: 100%;
  }

  .poloniex {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-image: url('https://s3.amazonaws.com/stellarup-assets/images/poloniex.png');
  }

  @media (min-width: $screen-xs) {
    .exchangesWrapper {
      margin-top: 0.5em;
      margin-bottom: 0.75em;
    }
    .exchangeWrap {
      width: 44.5%;
      margin: 0.5em 0 0 0.5em;
    }
  }

  @media (min-width: $screen-sm) {
  }

  @media (min-width: $screen-md) {
    .exchangesWrapper {
      flex-wrap: nowrap;
      padding-left: 0.5em;
      padding-right: 1em;
    }

    .exchangeWrap {
      width: 100%;
    }
  }

  @media (min-width: $screen-lg) {
  }
}
