.InfoToolTip {
  margin-left: 0.2em;
  margin-bottom: -0.1em;

  .icon {
    cursor: pointer;
    color: #6770e3;
    &:hover {
      opacity: 0.9;
    }
  }
}

.InfoToolTipPopOver {
  font-weight: 400;
  background-color: #fff;
  font-size: 14px;

  .title {
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ededed;
  }

  .info {
    color: #555;
  }

  .linkWrap {
    padding-bottom: 10px;
  }
}
